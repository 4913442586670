import React from "react"
import PropTypes from "prop-types"

// Components
import { graphql } from "gatsby"
import { BlogList, Container, Layout, Section, BackButton } from "../components"
import styled from "styled-components"

const Authors = ({ pageContext, data }) => {
  const { author } = pageContext
  const { edges, totalCount } = data.allMdx
  const authorHeader = `${totalCount} post${
    totalCount === 1 ? "" : "s"
  } written by "${author}"`

  return (
    <Layout>
      <Section>
        <Container>
          <div
            css={`
              padding-top: 1rem;
            `}
          >
            <BackButton to="/authors/" text="All Authors" />
            <Header>{authorHeader}</Header>
            <BlogList posts={edges} />
          </div>
        </Container>
      </Section>
    </Layout>
  )
}

const Header = styled.h2`
  color: var(--primary);
`

Authors.propTypes = {
  pageContext: PropTypes.shape({
    author: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
}

export default Authors

export const pageQuery = graphql`
  query($author: String) {
    allMdx(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { author: { in: [$author] } } }
    ) {
      totalCount
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            path
            date
            author
            length
          }
        }
      }
    }
  }
`
